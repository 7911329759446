html,
body {
  margin: 0;
  padding: 0;
}

a[x-apple-data-detectors] {
  color: inherit !important;
  text-decoration: none !important;
  font-size: inherit !important;
  font-family: inherit !important;
  font-weight: inherit !important;
  line-height: inherit !important;
}

#emailSignup {
  margin: 0 auto;
  width: 320px;
}

#emailSignup .header {
  padding: 10px 0 22px 0;
}

#emailSignup .logo {
  margin: 0 auto;
  width: 276px;
}

#emailSignup .main {
  padding-left: 20px;
  padding-right: 20px;
}

#emailSignup .footer {
  background-color: #000;
  padding: 10px 16px 27px 10px;
  margin-top: 12px;
}

#emailSignup h1 {
  font-family: "franklin-gothic-urw-cond", "Arial Narrow Bold", Arial, sans-serif;
  font-size: 18px;
  font-weight: 700;
  background-color: #cc071f;
  color: #fff;
  line-height: 26px;
  display: inline;
  padding-left: 3px;
  padding-right: 3px;
}

#emailSignup h2 {
  font-family: "franklin-gothic-urw-cond", "Arial Narrow Bold", Arial, sans-serif;
  font-size: 18px;
  font-weight: 700;
  background-color: #cc071f;
  color: #fff;
  line-height: 26px;
  display: inline;
  padding-left: 3px;
  padding-right: 3px;
}

#emailSignup p {
  font-family: "franklin-gothic-urw-cond", "Arial Narrow Bold", Arial, sans-serif;
  font-size: 12px;
  font-weight: 700;
  line-height: 18px;
  margin-top: 4px;
  margin-bottom: 20px;
}

#emailSignup p.requirements {
  font-family: "franklin-gothic-urw-cond", "Arial Narrow Bold", Arial, sans-serif;
  font-size: 8px;
  font-weight: 700;
  color: #2b2b2b;
  line-height: 12px;
  margin: 0;
  padding-top: 4px;
}

#emailSignup .footer p {
  font-family: "franklin-gothic-urw-cond", "Arial Narrow Bold", Arial, sans-serif;
  font-size: 10px;
  font-weight: 500;
  line-height: 14px;
  color: #fff;
  margin: 0;
}

#emailSignup ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

#emailSignup ul li {
  margin-top: 10px;
}

#emailSignup input[type=text],
input[type=email] {
  width: 100%;
  padding: 12px 6px 10px 6px;
  border: 1px solid #a5a5a5;
  font-family: "franklin-gothic-urw-cond", "Arial Narrow Bold", Arial, sans-serif;
  font-size: 15px;
  font-weight: 700;
  line-height: 15px;
  color: #787878;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  border-radius: 0;
}

#emailSignup button[type=submit] {
  font-family: "franklin-gothic-urw-cond", "Arial Narrow Bold", Arial, sans-serif;
  font-size: 15px;
  font-weight: 700;
  color: #cc071f;
  border: 3px solid #cc071f;
  display: block;
  width: 143px;
  height: 43px;
  cursor: pointer;
  background-color: #fff;
  -webkit-appearance: none;
  border-radius: 0;
}

#emailSignup .itemGroup {
  display: table;
  margin-top: 12px;
}

#emailSignup .itemGroup>.itemCell {
  display: table-cell;
  vertical-align: top;
  height: 43px;
}

#emailSignup .item {
  padding-top: 2px;
  padding-left: 60px;
  line-height: 16px;
}

#emailSignup .item a {
  font-family: "franklin-gothic-urw-cond", "Arial Narrow Bold", Arial, sans-serif;
  font-size: 14px;
  font-weight: 700;
  color: #000;
}

#emailSignup .footer a {
  color: #fff !important;
  text-decoration: none !important;
}