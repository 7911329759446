html, body {
  margin: 0;
  padding: 0;
}

a[x-apple-data-detectors] {
  color: inherit !important;
  text-decoration: none !important;
  font-size: inherit !important;
  font-family: inherit !important;
  font-weight: inherit !important;
  line-height: inherit !important;
}

#emailSignup {
  margin: 0 auto;
  width: 320px;
}

/* removed bottom padding */
#emailSignup .header {
  padding: 10px 0;
}

/* added padding, removed margin, updated width */
#emailSignup .logo {
  width: 200px;
  padding: 0 20px;
}

/* added img element */
#emailSignup .logo img {
  width: 160px;
}

#emailSignup .main {
  padding-left: 20px;
  padding-right: 20px;
}

/* updated background-color, padding, margin-top, added margin, border-width, border-style, border-color */
#emailSignup .footer {
  background-color: #f0f0f0;
  padding: 20px;
  margin: 0 20px;
  border-width: 1px 0 0 0;
  border-style: solid;
  border-color: #000;
}

/* updated font-family, font-size, background-color, color, padding-left, padding-right*/
#emailSignup h1 {
  font-family: "Open Sans", sans-serif;
  font-size: 24px;
  font-weight: 700;
  /* background-color: ; */
  color: #000;
  line-height: 26px;
  display: inline;
  padding-left: 0;
  padding-right: 0;
}

/* updated font-family, font-size, font-weight, margin top */
#emailSignup p {
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
  font-weight: normal;
  line-height: 18px;
  margin-top: 10px;
  margin-bottom: 20px;
}

/* updated font-family, font-weight, padding-top */
#emailSignup p.requirements {
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  font-weight: normal;
  color: #2b2b2b;
  line-height: 12px;
  margin: 0;
  padding-top: 17px;
}

/* updated font-family, font-size, color, margin */
#emailSignup .footer p {
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
  font-weight: 500;
  line-height: 14px;
  color: #000;
  /* margin: ; */
}

#emailSignup ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

#emailSignup ul li {
  margin-top: 17px;
}

/* updated font-family, font-size */
#emailSignup input[type=text], input[type=email], input[type=number] {
  width: 100%;
  padding: 12px 6px 10px 6px;
  border: 1px solid #a5a5a5;
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
  font-weight: 700;
  line-height: 15px;
  color: #787878;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  border-radius: 0;
}

/* added select element */
#emailSignup select {
  width: 100%;
  padding: 12px 6px 10px 6px;
  border: 1px solid #a5a5a5;
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
  font-weight: 700;
  line-height: 15px;
  color: #787878;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 0;
}

/* updated font-family, font-size, color, border, width, background-color */
#emailSignup button[type=submit] {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 700;
  color: #FFF;
  border: 0 solid #000;
  display: block;
  width: 128px;
  height: 43px;
  cursor: pointer;
  background-color: #000;
  -webkit-appearance: none;
  border-radius: 0;
}

/* added to submit1 (No Thanks link) styling */
#emailSignup #submit1 {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: normal;
  text-decoration: underline;
  text-align: left;
  line-height: 43px;
  color: 00529F;
  border-width: 0;
  display: block;
  width: 128px;
  height: 43px;
  cursor: pointer;
  background-color: #FFF;
  -webkit-appearance: none;
  border-radius: 0;
}

/* updated margin-top */
#emailSignup .itemGroup {
  display: table;
  margin-top: 0px;
}

#emailSignup .itemGroup>.itemCell {
  display: table-cell;
  vertical-align: top;
  height: 43px;
}

/*updated top padding */
#emailSignup .item {
  padding-top: 0;
  padding-left: 24px;
  line-height: 16px;
}

/*updated font-family */
#emailSignup .item a {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 700;
  color: #000;
}

/*updated color, text-decoration */
#emailSignup .footer a {
  color: #00529F;
  /* text-decoration: ; */
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}